<template>
  <div class="auth-wrapper auth-v1 px2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <b-img
            fluid
            :src="logoImg"
            alt="Logo Pantaleon"
            width="50px"
          />
          <h2 class="brand-text text-primary ml-1 flex align-items-center mb-0">
            Automatización
          </h2>
        </b-link>
        <b-card-title class="mb-1">
          Bienvenido! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Inicia sesión para acceder a nuestras aplicaciones
        </b-card-text>

        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label="Correo Electrónico"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="yo@pantaleon.com"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >El correo es requerido.</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >Por favor ingresa la contraseña.</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Recuérdame
                </b-form-checkbox>
              </b-form-group> -->

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="blockInputs"
              @click="validationForm"
            >
              Ingresar
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2 text-sm">
          <div>
            <span>¿Necesitas acceso? </span>
            <b-link :to="{name:'inicio'}">
              <span>&nbsp;Solicitar cuenta</span>
            </b-link>
          </div>
          <div>
            <b-link :to="{name:'inicio'}">
              <span>¿Olvidaste tu contraseña?</span>
            </b-link>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import authPanta from '@/../apis/Auth'
import firebase from 'firebase/app'
import 'firebase/auth'
import axios from '@axios'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: true,
      password: '',
      userEmail: '',
      logoImg: require('@/assets/images/logo/Logo-Pantaleon.png'),
      blockInputs: false,
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    userLogin(userData) {
      firebase.auth().signInWithEmailAndPassword(this.userEmail, this.password)
        .then(async userCredential => {
          axios.defaults.headers.common.Authorization = await userCredential.user.getIdToken()
          localStorage.setItem('currentUser', JSON.stringify(userData.data.data))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Bienvenido! 👋',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        // this.$router.push('/')
        })
        .catch(error => {
          const msg = error.code == 'auth/wrong-password' ? 'Contraseña Incorrecta.' : 'Ha ocurrido un error al iniciar sesión.'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: msg,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
          this.blockInputs = false
        })
    },
    validationForm() {
      this.blockInputs = true
      const send = { mail: this.userEmail }
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          authPanta.actions.verifyUser(send).then(response => {
            if (response.data.message == 'success') {
              this.userLogin(response)
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Usuario erróneo.',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
              this.blockInputs = false
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
